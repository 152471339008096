<template>
  <v-app id="clients-list" class="clients-list">
    <!--begin::Dashboard-->
    <div class="card card-custom card-stretch h-auto">
      <!--begin::Header-->
      <div class="card-header border-0 py-5 align-items-center">
        <h3 class="card-title align-items-start flex-column m-0 py-3">
          <span class="card-label font-weight-bolder text-dark"
            >Squads List</span
          >
        </h3>
        <button
          v-if="Object.keys(query).length"
          class="btn btn-light font-weight-bolder text-dark-75 ms-auto"
          @click="exportToCSV"
        >
          <template v-if="!exportToCSVisLoading">
            <v-icon>mdi-file-document-outline</v-icon> Export to CSV
          </template>
          <template v-else>
            <v-icon>mdi-loading mdi-spin</v-icon> Exporting...
          </template>
        </button>
        <button
          class="btn btn-light font-weight-bolder text-dark-75 ms-2"
          v-b-toggle.filter-box
        >
          <v-icon>mdi-tune</v-icon> Filter
        </button>
      </div>
      <!--end::Header-->
      <!--begin::Body-->

      <ClientsListFilter @applyFilter="applyFilter" />
      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <!--begin::Table-->
          <button
            class="btn btn-danger mb-3"
            v-if="selectedIds.length"
            @click="confirmDeleteSelectedClients"
          >
            Delete Selected Clients
          </button>
          <div
            class="table-responsive mb-0"
            v-if="clients && clients.length && clients.length > 0"
          >
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <thead>
                <tr class="text-left">
                  <th class="pr-0 pl-3">Select</th>
                  <th style="min-width: 130px">
                    <span class="text-dark-75 d-inline-flex align-items-center"
                      >Client Request Date
                    </span>
                  </th>
                  <th style="min-width: 130px">
                    <span class="text-dark-75 d-inline-flex align-items-center"
                      >Squad Request Date
                    </span>
                  </th>
                  <th style="min-width: 130px">
                    <span>Name/Email</span>
                  </th>
                  <th style="min-width: 120px">
                    <span>Mobile No.</span>
                  </th>
                  <th style="min-width: 130px">
                    <span>Official Squad Name</span>
                  </th>
                  <th style="min-width: 130px">
                    <span>Internal squad name</span>
                  </th>
                  <th style="min-width: 185px">
                    <span>industry</span>
                  </th>
                  <th style="min-width: 185px">
                    <span>Service Type</span>
                  </th>
                  <th style="min-width: 185px">
                    <span>Utilization</span>
                  </th>
                  <th style="min-width: 185px">
                    <span>Client Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(client, i) in clients"
                  :key="
                    client.client_project_id
                      ? client.client_project_id
                      : client.id + i + client.email
                  "
                  @click="
                    openLinkInNewTab(
                      client.client_project_id
                        ? `/dashboard/client/${client.id}/project/${client.client_project_id}/client-request`
                        : '/dashboard/client/' + client.id + '/client-request'
                    )
                  "
                >
                  <td class="px-0" @click.stop>
                    <v-checkbox
                      v-model="selectedIds"
                      :value="
                        client.client_project_id
                          ? client.client_project_id + 'project'
                          : client.id + 'client'
                      "
                      hide-details
                      class="m-0"
                    ></v-checkbox>
                  </td>
                  <td class="font-weight-bolder">
                    <div class="d-flex align-items-center">
                      {{
                        client.client_request_date
                          ? client.client_request_date
                          : "-"
                      }}
                    </div>
                  </td>
                  <td class="font-weight-bolder">
                    <div class="d-flex align-items-center">
                      {{
                        client.project_request_date
                          ? client.project_request_date
                          : "-"
                      }}
                    </div>
                  </td>
                  <td style="max-width: 185px">
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="text-dark-75 font-weight-bolder text-clamp-2"
                        >
                          {{ client.first_name }} {{ client.last_name }}
                        </div>
                        <div>
                          <span
                            class="text-muted font-size-sm"
                            style="word-break: break-all"
                          >
                            {{ client.email }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="font-weight-bolder">
                    <div class="d-flex align-items-center">
                      {{ client.phone || "-" }}
                    </div>
                  </td>
                  <td class="font-weight-bolder">
                    <div
                      class="d-flex align-items-center"
                      style="word-break: break-all"
                    >
                      {{ client.project_name || "-" }}
                    </div>
                  </td>
                  <td class="font-weight-bolder">
                    <div
                      class="d-flex align-items-center"
                      style="word-break: break-all"
                    >
                      {{ client.project_internal_name || "-" }}
                    </div>
                  </td>
                  <td class="font-weight-bolder">
                    <div class="d-flex align-items-center">
                      {{ client.industry || "-" }}
                    </div>
                  </td>
                  <td class="font-weight-bolder">
                    <div
                      class="d-block align-items-center"
                      v-if="client.service_type && client.service_type.length"
                    >
                      <div
                        class="text-dark-75 font-weight-bolder"
                        v-for="service in client.service_type"
                        :key="service"
                      >
                        <span class="d-block">{{
                          serviceTypeOptions.find(
                            (option) => option.value === service
                          ).text
                        }}</span>
                      </div>
                    </div>
                    <div class="d-flex align-items-center" v-else>-</div>
                  </td>
                  <td class="font-weight-bolder">
                    <div
                      class="d-flex align-items-center"
                      v-if="client.utilization"
                    >
                      {{
                        client.utilization == 1
                          ? "Full-time"
                          : client.utilization == 2
                          ? "Part-time"
                          : "Depends on cost"
                      }}
                    </div>
                    <div class="d-flex align-items-center" v-else>-</div>
                  </td>
                  <td class="font-weight-bolder">
                    <div class="d-flex align-items-center">
                      {{
                        client.client_status == 0
                          ? "Uncompleted lead"
                          : client.client_status == 1
                          ? "Completed lead"
                          : "Active  Client"
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h5
            class="my-5 text-muted text-center"
            v-if="clients && clients.length === 0"
          >
            No Results Found
          </h5>
          <div class="pagination-container">
            <p
              v-if="clients && clients.length > 0"
              class="pagination-container__desc"
            >
              {{
                $t("showing_results", {
                  length: clients.length,
                  total: clientsMeta.total,
                })
              }}
            </p>
            <b-pagination
              v-if="clientsMeta && clientsMeta.last_page > 1"
              v-model="page"
              :total-rows="clientsMeta.total"
              :per-page="perPage"
              align="center"
              last-number
              first-number
            ></b-pagination>
          </div>

          <!--end::Table-->
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Dashboard-->
  </v-app>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import toasted from "@/core/plugins/vue-toasted";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ClientsListFilter from "@/components/clients-list/ClientsListFilter.vue";
export default {
  name: "ClientsList",
  components: { ClientsListFilter },
  data() {
    return {
      page: 1,
      perPage: 8,
      activeQualifiedId: null,
      activeContactedId: null,
      exportToCSVisLoading: false,
      query: {},
      selectedIds: [],
      serviceTypeOptions: [
        {
          text: "Team as a serivce",
          value: 1,
        },
        {
          text: "Creative Lab",
          value: 2,
        },
        {
          text: "CTO as a serivce",
          value: 3,
        },
        {
          text: "Payroll Management",
          value: 4,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      clients: "clients/getClientsData",
      clientsMeta: "clients/getClientsMeta",
    }),
    clientsQuery() {
      return {
        page: this.page,
        perPage: this.perPage,
        ...this.query,
      };
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Clients" },
    ]);
  },
  watch: {
    page() {
      this.handleGettingClientsData();
    },
  },
  created() {
    this.handleGettingClientsData();
  },
  methods: {
    ...mapActions({
      getClientsData: "clients/getClientsData",
      deleteSelectedClients: "clients/deleteSelectedClients",
    }),
    handleGettingClientsData() {
      this.getClientsData(this.clientsQuery);
    },
    openLinkInNewTab(url) {
      window.open(url, "_blank");
    },
    registerAtDate(date) {
      const convertedDate = date.split("T")[0];

      return convertedDate;
    },
    async exportToCSV() {
      try {
        this.exportToCSVisLoading = true;
        const response = await axios.get("/users/clients", {
          params: this.query,
          responseType: "blob",
          headers: {
            Accept: "text/csv",
          },
        });

        const date = new Date().toISOString().split("T")[0];
        const moduleName = "Clients"; // Change this to "clients" if needed
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${moduleName}_${date}.csv`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.exportToCSVisLoading = false;
        console.log(error);
      } finally {
        this.exportToCSVisLoading = false;
      }
    },
    confirmDeleteSelectedClients() {
      Swal.fire({
        title: "Are you sure",
        text: "You want to permanently delete the selected Client accounts ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            users: this.selectedIds
              .filter((user) => user.includes("client"))
              .map((user) => user.replace("client", "")),
            projects: this.selectedIds
              .filter((user) => user.includes("project"))
              .map((user) => user.replace("project", "")),
          };

          this.deleteSelectedClients(payload).then(() => {
            // load updated talent list
            this.page = 1;
            this.handleGettingClientsData();

            // reset selected Ids
            this.selectedIds = [];
            toasted.show("Selected Clients Deleted Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        }
      });
    },
    applyFilter(query) {
      this.query = query;
      this.page = 1;
      this.handleGettingClientsData();
    },
  },
};
</script>

<style lang="scss" scoped>
.table.table-vertical-center {
  tr {
    &:hover {
      background: #f8f9fa;
    }
  }
  th {
    color: #3f4254 !important;
  }
  td {
    padding-top: 16px;
    vertical-align: top;
    &:not(:first-of-type, :last-of-type) {
      cursor: pointer;
    }
  }
}
::v-deep {
  .v-input--selection-controls .v-input__slot {
    justify-content: center;
  }
  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-input--selection-controls.v-input--checkbox {
    padding: 0;
  }
}
</style>
